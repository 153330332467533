import { push } from "gatsby";
import { getStore } from "./Store";
import Actions from "./Reducers/Actions";
import ApiProxy from "./ApiProxy";
import Catchify from "./Utils/Catchify";
import API from "./Api";
import * as Services from "./Services";
// import SlsApi from "./SlsApi";

export default dispatch => ({
  navActions: {
    push: Catchify(async url => {
      push(`/${url}`);
    })
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    login: Catchify(
      async ({ username, password }) => {
        let response = await API.login({ username, password });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem("token", response.token);
          window.localStorage.setItem("id", response.id);
        } catch (err) {
          console.log("cannot set localStorage! ");
        }
        dispatch({ type: Actions.LOGIN, payload: response });
        let cartResponse = await API.sls.getCart();
        dispatch({ type: Actions.SET_CART_DATA, payload: cartResponse });
      },

      async err => {
        ApiProxy.setToken(null);
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        console.warn(err);
        throw err;
      }
    ),

    getMyProfile: Catchify(
      async () => {
        let response = await API.getMyProfile();
        dispatch({ type: Actions.AUTO_LOGIN, payload: response });
      },

      async err => {
        console.warn(err);
        throw err;
      }
    ),

    autoLogin: Catchify(
      async () => {
        let token = window.localStorage.getItem("token");
        ApiProxy.setToken(token);
        if (token) {
          let response = await API.getMyProfile();
          dispatch({ type: Actions.AUTO_LOGIN, payload: response });
          let cartResponse = await API.sls.getCart();
          dispatch({ type: Actions.SET_CART_DATA, payload: cartResponse });
        }
      },

      async err => {
        console.warn(err);
        ApiProxy.setToken(null);
        window.localStorage.removeItem("token");
        throw err;
      }
    ),

    logout: Catchify(async () => {
      ApiProxy.setToken(null);
      window.localStorage.removeItem("id");
      window.localStorage.removeItem("token");
      dispatch({ type: Actions.LOGOUT, payload: null });
    }),

    register: async ({ username, email, password }) => {
      try {
        await API.register({ username, email, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetPassword: async ({ email }) => {
      try {
        await API.resetPassword({ email });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetValidation: async ({ username, password }) => {
      try {
        await API.resetValidation({ username, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    socialSingin: async ({ socialType, token }) => {
      try {
        let response = await API.socialSingin({ socialType, token });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem("token", response.token);
          window.localStorage.setItem("id", response.id);
        } catch (err) {
          console.log("cannot set localStorage! ");
        }
        dispatch({ type: Actions.LOGIN, payload: response });
        return Promise.resolve();
      } catch (err) {
        ApiProxy.setToken(null);
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        console.warn(err);
        return Promise.reject(err);
      }
    },

    editMyProfile: Catchify(
      async data =>
        dispatch({
          type: Actions.AUTO_LOGIN,
          payload: await API.editMyProfile(data)
        }),
      err => Promise.reject(err)
    ),

    preCheckout: Catchify(
      async data => {
        let result = await API.preCheckout(JSON.stringify(data));
        return result;
      },
      err => Promise.reject(err)
    ),

    fetchTours: async ({ limit, sort, offset, search, label }) => {
      try {
        let results = API.fetchTours({ limit, sort, offset, search, label });
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchTourById: async id => {
      try {
        let result = API.fetchTourById(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchProducts: async ({ limit, sort, offset, search, label, tour }) => {
      try {
        let results = API.fetchProducts({
          limit,
          sort,
          offset,
          search,
          label,
          tour
        });
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchProductById: async id => {
      try {
        let result = API.fetchProductById(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchArticles: async ({ limit, sort, offset, search, label }) => {
      try {
        let results = API.fetchArticles({ limit, sort, offset, search, label });
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchArticleById: async id => {
      try {
        let result = API.fetchArticleById(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    createOrder: Catchify(
      async data => {
        let result = await API.createOrder(data);
        return result;
      },
      err => Promise.reject(err)
    ),

    paypalOnSuccess: Catchify(
      async (uid, data) => {
        return await API.paypalOnSuccess(uid, JSON.stringify(data));
      },
      err => Promise.reject(err)
    ),

    getOrderList: Catchify(
      async () => {
        let result = await API.getOrderList();
        dispatch({ type: Actions.SET_ORDERS, payload: result });
        return result;
      },
      err => Promise.reject(err)
    ),

    getOrder: Catchify(
      async id => {
        let result = await API.getOrder(id);
        dispatch({ type: Actions.SET_ORDER, payload: result });
        return result;
      },
      err => Promise.reject(err)
    ),

    getCart: async () => {
      let result = await API.sls.getCart();
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    addItemToCart: async ({ product, quantity }) => {
      let result = await API.sls.addItemToCart({ product, quantity });
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    removeItemFromCart: async index => {
      let result = await API.sls.removeItemFromCart(index);
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    ...Services.getActionCreators(getStore())
  }
});
