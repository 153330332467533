import ApiProxy from "../ApiProxy";

const SlsHost = "https://i5w9tsrsob.execute-api.ap-northeast-1.amazonaws.com";

const SlsApi = {
  async getCart() {
    return ApiProxy.get({
      path: `${SlsHost}/dev/cart?token=${ApiProxy.apiToken}`,
      withHost: true
    });
  },

  async addItemToCart({ product, quantity }) {
    return ApiProxy.post({
      path: `${SlsHost}/dev/cart/add?token=${ApiProxy.apiToken}`,
      data: { product, quantity },
      withHost: true
    });
  },

  async removeItemFromCart(index) {
    return ApiProxy.post({
      path: `${SlsHost}/dev/cart/remove?token=${ApiProxy.apiToken}`,
      data: { index },
      withHost: true
    });
  }
};

export default SlsApi;
