import { combineReducers } from "redux";
import user from "./User";
import lang from "./Lang";
import product from "./Product";
import order from "./Order";
import cart from "./Cart";
import { getReducers } from "../Services";

export default combineReducers({
  user,
  lang,
  product,
  order,
  cart
  // ...getReducers(),
});
