import React from "react";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import * as Widget from "../../Components/Widget";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import Login from "../../Components/Login";
import ProfileTabs from "../../Components/ProfileTabs";

class ProfilePage extends React.Component {
  render() {
    let { appActions, navActions, profile, itemsInCart } = this.props;

    return (
      <Widget.MaxWidth width={800} extraCss="padding: 20px">
        {profile ? (
          <div>
            <Widget.Center extraCss="margin-bottom: 20px;">
              <h1 style={{ paddingBottom: 5, borderBottom: "1px solid grey" }}>
                個人頁面
              </h1>
              <Widget.Row extraCss="margin-top: 20px;">
                <Widget.Button
                  style={{ marginRight: 10 }}
                  label={`購物車(${itemsInCart})`}
                  onClick={() => navActions.push("/cart")}
                />
                <Widget.Button
                  label="登出"
                  onClick={() => appActions.logout()}
                />
              </Widget.Row>
            </Widget.Center>
            <ProfileTabs />
          </div>
        ) : (
          <Widget.Center>
            <Login.Button />
          </Widget.Center>
        )}
      </Widget.MaxWidth>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      itemsInCart:
        (Selectors.getCartData(state) &&
          Selectors.getCartData(state).items.length) ||
        0
    }),
    ActionCreator
  )(ProfilePage)
);
