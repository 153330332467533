const AppConfig = {
  navbar: {
    coverContent: false,
    background: "#e6e6e6",
    altBackground: "#e6e6e6",
    textColor: "#4E4E4E",
    textShadow: "2px 2px #ccc",
    height: 60
  },

  layout: {
    breakPointMobile: 500
  },

  productListing: {
    layoutType: "grid"
  },

  articleListing: {
    layoutType: "list"
  },

  footer: {}
};

export default AppConfig;
