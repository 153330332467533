import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget";
import OrderItem from "./OrderItem";

class OrderList extends React.Component {
  componentDidMount() {
    let { appActions } = this.props;

    appActions.getOrderList();
  }

  render() {
    let { extraCss, orders } = this.props;

    if (!orders) {
      return (
        <Widget.Center extraCss="margin-top: 10px;">
          <Widget.Spinner />
        </Widget.Center>
      );
    }

    return (
      <Wrapper extraCss={extraCss}>
        {orders
          .sort((o1, o2) => o2.id - o1.id)
          .map(o => (
            <OrderItem key={o.id} extraCss="margin-top: 10px;" order={o} />
          ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${props => props.extraCss || ""};
`;

export default connect(
  (state, ownProps) => ({
    orders: Selectors.getOrderList(state)
  }),
  ActionCreator
)(OrderList);
