const ApiProxy = require("../ApiProxy");

module.exports = {
  async bootstrap() {
    return ApiProxy.get({
      path: "/api/bootstrap/"
    });
  },

  async login({ username, password }) {
    return ApiProxy.post({
      path: "/api/user/login/",
      data: { username, password }
    });
  },

  async socialSingin({ socialType, token }) {
    return ApiProxy.post({
      path: `/api/social_signin/${socialType}/`,
      data: { token }
    });
  },

  async getProfile(id) {
    return ApiProxy.get({
      path: `/api/user/profile/${id}/`
    });
  },

  async getMyProfile() {
    return ApiProxy.get({
      path: `/api/user/profile/`
    });
  },

  async editMyProfile(data) {
    let formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    return ApiProxy.put({
      path: "/api/user/profile/",
      data
    });
  },

  async register({ username, email, password }) {
    return ApiProxy.post({
      path: "/api/user/register/",
      data: { username, email, password }
    });
  },

  async resetValidation({ username, password }) {
    return ApiProxy.post({
      path: "/api/users/reset_validation/",
      data: {
        username,
        password
      }
    });
  },

  async changePassword({ username, password, newPassword }) {
    return ApiProxy.post({
      path: `/api/user/change_password/`,
      data: {
        username,
        password,
        new_password: newPassword
      }
    });
  },

  async resetPassword({ email }) {
    return ApiProxy.post({
      path: `/api/users/reset_password/`,
      data: {
        email
      }
    });
  },

  async fetchTours({ limit, offset, search, label, sort }) {
    return ApiProxy.get({
      path: `/api/tour/?limit=${limit}&offset=${offset}${
        search ? `&search=${search}` : ""
      }${label ? `&label=${label}` : ""}${sort ? `&ordering=${sort}` : ""}`
    });
  },

  async fetchTourById(id) {
    return ApiProxy.get({
      path: `/api/tour/${id}/`
    });
  },

  async fetchProducts({ limit, offset, search, label, sort, tour }) {
    return ApiProxy.get({
      path: `/api/product?limit=${limit}&offset=${offset}${
        search ? `&search=${search}` : ""
      }${label ? `&label=${label}` : ""}${sort ? `&ordering=${sort}` : ""}${
        tour ? `&tour=${tour}` : ""
      }`
    });
  },

  async fetchProductById(id) {
    return ApiProxy.get({
      path: `/api/product/${id}`
    });
  },

  async fetchArticleById(id) {
    return ApiProxy.get({
      path: `/api/article/${id}`
    });
  },

  async fetchArticles({ limit, offset, search, label, sort }) {
    return ApiProxy.get({
      path: `/api/article?limit=${limit}&offset=${offset}${
        search ? `&search=${search}` : ""
      }${label ? `&label=${label}` : ""}${sort ? `&ordering=${sort}` : ""}`
    });
  },

  async fetchItemById(id) {
    return ApiProxy.get({
      path: `/api/items/${id}/`
    });
  },

  async fetchItemsByCategory(id) {
    return ApiProxy.get({
      path: `/api/categories/${id}/items/`
    });
  },

  async fetchItemComments(id) {
    return ApiProxy.get({
      path: `/api/items/${id}/comments/`
    });
  },

  async createItemComment({ item, text }) {
    return ApiProxy.post({
      path: `/api/items/${item.id}/comments/`,
      data: {
        text
      }
    });
  },

  async editItemComment({ item, itemComment, text }) {
    return ApiProxy.put({
      path: `/api/items/${item.id}/comments/${itemComment.id}/`,
      data: {
        text
      }
    });
  },

  async deleteItemComment({ item, itemComment }) {
    return ApiProxy.delete({
      path: `/api/items/${item.id}/comments/${itemComment.id}/`
    });
  },

  async createItemCommentResponse({ item, comment, text }) {
    return ApiProxy.post({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/`,
      data: {
        text
      }
    });
  },

  async editItemCommentResponse({ item, comment, response, text }) {
    return ApiProxy.put({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/${
        response.id
      }/`,
      data: {
        text
      }
    });
  },

  async deleteItemCommentResponse({ item, comment, response }) {
    return ApiProxy.delete({
      path: `/api/items/${item.id}/comments/${comment.id}/responses/${
        response.id
      }/`
    });
  },

  async preCheckout(data) {
    return ApiProxy.post({
      secure: true,
      path: `/api/pre_checkout/`,
      data: {
        data
      }
    });
  },

  async createOrder({ data, currency, paymentType, title }) {
    return ApiProxy.post({
      secure: true,
      path: `/checkout/order/`,
      data: {
        title,
        currency,
        payment_type: paymentType,
        data
      }
    });
  },

  async paypalOnSuccess(uid, data) {
    return ApiProxy.post({
      secure: true,
      path: `/api/paypal/result/${uid}/`,
      data
    });
  },

  async getOrderList() {
    return ApiProxy.get({
      secure: true,
      path: `/api/order/`
    });
  },

  async getOrder(id) {
    return ApiProxy.get({
      secure: true,
      path: `/checkout/order/${id}/`
    });
  },

  // TODO: should not put Services API here, temporary for update-content.js usage
  // Catalog
  async getCategories() {
    // categories.json
    return ApiProxy.get({
      secure: false,
      path: `/api/categories/items/`
    });
  },

  async getItems() {
    // products.json
    return ApiProxy.get({
      secure: false,
      path: `/api/items/`
    });
  }
};
