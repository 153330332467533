import Actions from "./Actions";

const initialState = {
  list: [],
  details: null
};

const FAKE_DATA_STATE = {
  list: [1],
  details: {
    "1": {
      id: 1,
      code: "RT201904076071339575",
      payment: "線上刷卡",
      place: "機場台北松山(取貨時間7:00-22:00)",
      status: "NOT_PAID_YET",
      createAt: "2019-04-08 03:52",
      meta: {
        list: [1, 2],
        detail: {
          items: {
            "1": {
              id: 1,
              name: "日本 三合一上網SIM卡",
              quantity: 1,
              price: 100,
              flow: "1GB/天",
              days: "5天"
            },
            "2": {
              id: 2,
              name: "日本 八合一上網SIM卡",
              quantity: 2,
              price: 250,
              flow: "2GB/天",
              days: "10天"
            }
          },
          shippingFee: 65
        }
      }
    }
  }
};

export default function order(state = FAKE_DATA_STATE, { type, payload }) {
  switch (type) {
    default:
      return state;
  }
}

export function getOrderDetailById(state, id) {
  return state.details[id];
}

export function getOrderList(state) {
  return state.list.map(id => state.details[id]);
}

export function getCartListByOrderId(state, id) {
  return state.details[id].meta.list.map(
    productId => state.details[id].meta.detail.items[productId]
  );
}

export function getCartDetailByOrderId(state, id) {
  return state.details[id].meta.detail;
}

// import Actions from './Actions';
//
// const initialState = {
//   list: null,
//   map: {},
// };
//
// export default function reducer(state = initialState, { type, payload }) {
//   switch (type) {
//     case Actions.SET_ORDER:
//       return {
//         ...state,
//         map: {
//           ...state['map'],
//           [payload.id]: payload,
//         },
//       };
//     case Actions.SET_ORDERS:
//       return {
//         ...state,
//         list: payload,
//       };
//     default:
//       break;
//   }
//
//   return state;
// }
//
// export function getOrderDetailById(state, id) {
//   return state['map'][id];
// }
//
// export function getOrderList(state) {
//   console.log('state: ', state);
//
//   return state['list'];
// }
